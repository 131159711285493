<template>
    <div class="auth-wrapper auth-v1"
         style="padding: 0;">
        <div class="auth-inner main-container-width">
            <v-row>
                <v-col cols="12"
                       sm="12"
                       md="12"
                       lg="12"
                       class="mx-auto">

                    <v-card flat>
                        <app-header></app-header>
                        <div class="mx-8 pb-5">
                            <v-row class="mt-10 mx-8">
                                <v-col cols="12"
                                       lg="8"
                                       md="6"
                                       sm="12">
                                    <p class="text-lg-2xl text-xl font-weight-semibold text-black pt-1"
                                       style="margin-bottom: 0;">
                                        <BackAction text=""
                                                    :output="output" />
                                        {{ $t('FAQs') }}
                                    </p>
                                </v-col>
                                <v-col cols="12"
                                       lg="4"
                                       md="6"
                                       sm="12">
                                    <v-text-field outlined
                                                  v-model="searchKeyword"
                                                  @keyup="filterTopics"
                                                  type="text"
                                                  :placeholder="$t('Search')"
                                                  hide-details="auto"></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row class="mx-8">
                                <v-col lg="4">
                                    <v-card class="">
                                        <v-list class="card-faq-background pa-5 pb-8">
                                            <v-list-subheader class="font-weight-semibold text-black"
                                                              style="font-size: 14px;">
                                                {{ $t('Explore topics') }}
                                            </v-list-subheader>
                                            <div class="mt-2"
                                                 v-for="(item, i) in topics"
                                                 :key="i"
                                                 :value="item"
                                                 active-color="primary">
                                                <FaqBox type="topic"
                                                        :item="item"
                                                        :selectedLanguage="selectedLanguage"
                                                        @selected="exploreTopic(item)"
                                                        :hasIcon="true"></FaqBox>
                                            </div>
                                        </v-list>
                                    </v-card>
                                </v-col>

                                <v-col lg="8">
                                    <v-card class="mt-3 mb-10 card-faq-background">
                                        <v-row class="faq-card">
                                            <v-col lg="6" id="ques">
                                                <v-list :class="{ 'mr-3': selectedLanguage == 'ar' }"
                                                        class="card-faq-background">
                                                    <v-list-subheader class="font-weight-semibold text-black"
                                                                      style="font-size: 14px;">{{ $t('Popular FAQs')
                                                                      }}</v-list-subheader>
                                                    <div lines="three"
                                                         class="mt-2"
                                                         v-for="(item, i) in selectedQuestions"
                                                         :key="i"
                                                         :value="item"
                                                         active-color="primary">

                                                        <FaqBox 
                                                                type="question"
                                                                :item="item"
                                                                :selectedLanguage="selectedLanguage"
                                                                @selected="onSelectQuestion(item)"
                                                                :hasIcon="false"></FaqBox>
                                                    </div>
                                                </v-list>
                                            </v-col>
                                            <v-col lg="6" id="ansr">
                                                <v-card class="white-card-background mt-10">
                                                    <v-card-text>
                                                        <p 
                                                           class="font-weight-semibold text-color-black"
                                                           style="font-size: 16px;">
                                                            {{ $t(question) }}
                                                        </p>
                                                        <p style="font-size: 12px;"
                                                           v-html="getFaqs(answer)">
                                                        </p>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card>
                </v-col>
            </v-row>

        </div>

    </div>
</template>

<script>
import AppHeader from '../test/AppHeader.vue';
import { mdiRefresh, mdiChevronUp, mdiClose } from '@mdi/js';
import FaqBox from './FaqBox.vue';
import store from '@/store';

import BackAction from '@/@core/BackAction.vue';

import FAQs from '@/@core/utils/FAQs';


export default {
    components: {
        AppHeader,
        FaqBox,
        BackAction
    },
    data: () => ({
        searchKeyword: '',
        question: '',
        answer: '',
        selectedLanguage: 'en',
        topics: [
            {
                icon: require('@/assets/images/faqs/pay-bills.png'),
                selected: false,
                heading: 'account_managemant',
                questions: [
                    // {
                    //     selected: false,
                    //     heading: 'account_managemant_q1',
                    //     answer: 'account_managemant_ans1'
                    // },
                    {
                        selected: false,
                        heading: 'account_managemant_q2',
                        answer: 'account_managemant_ans2'
                    },
                    {
                        selected: false,
                        heading: 'account_managemant_q3',
                        answer: 'account_managemant_ans3'
                    },
                    {
                        selected: false,
                        heading: 'account_managemant_q4',
                        answer: 'account_managemant_ans4'
                    },
                    {
                        selected: false,
                        heading: 'account_managemant_q5',
                        answer: 'account_managemant_ans5'
                    }
                ]
            },
            {
                icon: require('@/assets/images/faqs/manage-account.png'),
                selected: false,
                heading: 'pay_bils',
                questions: [{
                    selected: false,
                    heading: 'pay_bils_q1',
                    answer: 'pay_bils_ans1'
                },

                {
                    selected: false,
                    heading: 'pay_bils_q2',
                    answer: 'pay_bils_ans2'
                }]
            },
            {
                icon: require('@/assets/images/faqs/membership-points.png'),
                selected: false,
                heading: 'statements',
                questions: [{
                    selected: false,
                    heading: 'statements_q1',
                    answer: 'statements_ans1'
                },
                {
                    selected: false,
                    heading: 'statements_q2',
                    answer: 'statements_ans2'
                },
                {
                    selected: false,
                    heading: 'statements_q3',
                    answer: 'statements_ans3'
                }
                ]
            },
            {
                icon: require('@/assets/images/faqs/amex-offer.png'),
                selected: false,
                heading: 'Transactions',
                questions: [{
                    selected: false,
                    heading: 'Transactions_q1',
                    answer: 'Transactions_ans1'
                },
                {
                    selected: false,
                    heading: 'Transactions_q2',
                    answer: 'Transactions_ans2'
                },
                {
                    selected: false,
                    heading: 'Transactions_q3',
                    answer: 'Transactions_ans3'
                },
                {
                    selected: false,
                    heading: 'Transactions_q4',
                    answer: 'Transactions_ans4'
                },
                {
                    selected: false,
                    heading: 'Transactions_q5',
                    answer: 'Transactions_ans5'
                },
                {
                    selected: false,
                    heading: 'Transactions_q6',
                    answer: 'Transactions_ans6'
                },
                {
                    selected: false,
                    heading: 'Transactions_q7',
                    answer: 'Transactions_ans7'
                }]
            },
            {
                icon: require('@/assets/images/faqs/fraud-protection.png'),
                selected: false,
                heading: 'Payments',
                questions: [{
                    selected: false,
                    heading: 'Payments_q1',
                    answer: 'Payments_ans1'
                },
                {
                    selected: false,
                    heading: 'Payments_q2',
                    answer: 'Payments_ans2'
                }]
            },
            {
                icon: require('@/assets/images/faqs/transfer-funds.png'),
                selected: false,
                heading: 'Travel',
                questions: [{
                    selected: false,
                    heading: 'Travel_q1',
                    answer: 'Travel_ans1'
                },
                {
                    selected: false,
                    heading: 'Travel_q2',
                    answer: 'Travel_ans2'
                },
                {
                    selected: false,
                    heading: 'Travel_q3',
                    answer: 'Travel_ans3'
                },
                {
                    selected: false,
                    heading: 'Travel_q4',
                    answer: 'Travel_ans4'
                }]
            },
            {
                icon: require('@/assets/images/faqs/statments.png'),
                selected: false,
                heading: 'Statements',
                questions: [{
                    selected: false,
                    heading: 'statements_q1',
                    answer: 'statements_ans1'
                },
                {
                    selected: false,
                    heading: 'statements_q2',
                    answer: 'statements_ans2'
                },
                {
                    selected: false,
                    heading: 'statements_q3',
                    answer: 'statements_ans3'
                }]
            },
            {
                icon: require('@/assets/images/faqs/managing-cards.png'),
                selected: false,
                heading: 'Amex_KSA_App',
                questions: [{
                    selected: false,
                    heading: 'Amex_KSA_App_q1',
                    answer: 'Amex_KSA_App_ans1'
                },
                {
                    selected: false,
                    heading: 'Amex_KSA_App_q2',
                    answer: 'Amex_KSA_App_ans2'
                },
                {
                    selected: false,
                    heading: 'Amex_KSA_App_q3',
                    answer: 'Amex_KSA_App_ans3'
                },
                {
                    selected: false,
                    heading: 'Amex_KSA_App_q4',
                    answer: 'Amex_KSA_App_ans4'
                }
                ]
            },
            {
                icon: require('@/assets/images/faqs/billing-dispute.png'),
                selected: false,
                heading: 'Rewards',
                questions: [{
                    selected: false,
                    heading: 'Rewards_q1',
                    answer: 'Rewards_ans1'
                },
                {
                    selected: false,
                    heading: 'Rewards_q2',
                    answer: 'Rewards_ans2'
                },
                {
                    selected: false,
                    heading: 'Rewards_q3',
                    answer: 'Rewards_ans3'
                },
                {
                    selected: false,
                    heading: 'Rewards_q4',
                    answer: 'Rewards_ans4'
                },
                {
                    selected: false,
                    heading: 'Rewards_q5',
                    answer: 'Rewards_ans5'
                },
                {
                    selected: false,
                    heading: 'Rewards_q6',
                    answer: 'Rewards_ans6'
                },
                {
                    selected: false,
                    heading: 'Rewards_q7',
                    answer: 'Rewards_ans7'
                },
                {
                    selected: false,
                    heading: 'Rewards_q8',
                    answer: 'Rewards_ans8'
                },
                {
                    selected: false,
                    heading: 'Rewards_q9',
                    answer: 'Rewards_ans9'
                },
                {
                    selected: false,
                    heading: 'Rewards_q10',
                    answer: 'Rewards_ans10'
                },
                {
                    selected: false,
                    heading: 'Rewards_q11',
                    answer: 'Rewards_ans11'
                },
                {
                    selected: false,
                    heading: 'Rewards_q12',
                    answer: 'Rewards_ans12'
                }
                ]
            },
            {
                icon: require('@/assets/images/faqs/going-abroad.png'),
                selected: false,
                heading: 'Online_Portal',
                questions: [
                    {
                        selected: false,
                        heading: 'Online_Portal_q1',
                        answer: 'Online_Portal_ans1'
                    },
                    {
                        selected: false,
                        heading: 'Online_Portal_q2',
                        answer: 'Online_Portal_ans2'
                    },
                    {
                        selected: false,
                        heading: 'Online_Portal_q3',
                        answer: 'Online_Portal_ans3'
                    },
                    {
                        selected: false,
                        heading: 'Online_Portal_q4',
                        answer: 'Online_Portal_ans4'
                    },
                    {
                        selected: false,
                        heading: 'Online_Portal_q5',
                        answer: 'Online_Portal_ans5'
                    },
                    {
                        selected: false,
                        heading: 'Online_Portal_q6',
                        answer: 'Online_Portal_ans6'
                    }, {
                        selected: false,
                        heading: 'Online_Portal_q7',
                        answer: 'Online_Portal_ans7'
                    }
                ]
            }
        ],
        selectedQuestions: [],
        tempTopics: [],
        text: 'Back',
        output: false
    }),
    mounted()
    {
        let lang = localStorage.getItem('language');
        if (lang)
        {
            this.selectedLanguage = localStorage.getItem('language');
        }
        this.topics[0].selected = true;
        this.selectedQuestions = this.topics[0].questions;
        this.selectedQuestions[0].selected = true;
        this.question = this.selectedQuestions[0].heading;
        this.answer = this.selectedQuestions[0].answer;
        this.tempTopics = this.topics;
    },
    created()
    {
        store.subscribe((mutation) =>
        {
            if (mutation.type === 'accounts/SET_LANGUAGE')
            {
                this.selectedLanguage = mutation.payload;
            }
        })
    },
    methods: {
        getFaqs(name)
        {
            return FAQs.getFaqs(this.selectedLanguage, name)
        },
        exploreTopic(topic)
        {
            // console.log(topic)
            this.topics.forEach(element =>
            {
                element.selected = false;
                element.questions.forEach(element =>
                {
                    element.selected = false;
                });
            });
            topic.selected = true;
            this.selectedQuestions = topic.questions;
            this.selectedQuestions[0].selected = true;
            this.question = this.selectedQuestions[0].heading;
            this.answer = this.selectedQuestions[0].answer;

            // var myElement = document.getElementById('ques');
            // var topPos = myElement.offsetTop;
            // window.scrollTo(0, topPos);

            // document.getElementById('ques').scrollIntoView();

            const myElement = document.getElementById('ques');
            this.$vuetify.goTo(myElement, {
                duration: 500,
                offset: 0,
                easing: 'easeInOutCubic'
            });
        },
        onSelectQuestion(question)
        {
            this.selectedQuestions.forEach(element =>
            {
                element.selected = false;
            });
            question.selected = true;
            this.question = question.heading;
            this.answer = question.answer;

            // document.getElementById('ansr').scrollIntoView();
            // var topPos = myElement.offsetTop;
            // window.scrollTo(0, topPos);
            const myElement2 = document.getElementById('ansr');
            this.$vuetify.goTo(myElement2, {
                duration: 500,
                offset: 0,
                easing: 'easeInOutCubic'
            });
        },
        filterTopics()
        {
            this.topics = this.tempTopics.filter(top =>
            {
                if (top.heading)
                {
                    return top.heading.toLowerCase().includes(this.searchKeyword.toLowerCase())
                }
            });

            this.topics.forEach(element =>
            {
                element.selected = false;
                element.questions.forEach(element =>
                {
                    element.selected = false;
                });
            });

            this.topics[0].selected = true;
            this.selectedQuestions = this.topics[0].questions;
            this.selectedQuestions[0].selected = true;
            this.question = this.selectedQuestions[0].heading;
            this.answer = this.selectedQuestions[0].answer;
        }
    },
    icons: {
        mdiChevronUp,
        mdiRefresh,
        mdiClose,
    },

}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

::v-deep .v-input__control>.v-input__slot fieldset {
    color: rgba(94, 86, 105, 0.22);
    height: 51px;
}

input {
    color: rgba(94, 86, 105, 0.87);
    margin-bottom: 10px !important;
}

.faq-card {
    padding: 15px;

}

.next-arrow {}

.card {
    min-height: 49px;
    padding: 9px 15px;
    width: 20rem;
}

::v-deep .v-input input {
    max-height: 32px;
    padding-bottom: 17px;
}

.search-icon {
    position: relative;
    top: 38px;
    left: 14px;
    right: 14px;
}

.cross {
    position: absolute;
    right: 7.4%;
    top: 22%;
    cursor: pointer;
    z-index: 100;
}

.v-text-field.v-text-field--enclosed {
    margin: 0;
    padding: 0;
    height: 66px;
}

::v-deep .v-application.theme--light .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    box-shadow: none !important;
}

::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    /* box-shadow: 0 1px 3px 0 rgb(94 86 105 / 20%), 0 3px 1px -2px rgb(94 86 105 / 12%), 0 2px 2px 0 rgb(94 86 105 / 14%); */
    box-shadow: none !important;
}

.v-application.theme--light .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}

::v-deep .v-input__append-inner .v-input__icon .v-icon {
    transform-origin: 10px;
    padding-bottom: 10px;
}

::v-deep .v-text-field--single-line .v-select__selections {
    padding-bottom: 10px !important;
}

::v-deep .v-input__control>.v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details {
    padding: 0 35px !important;
}

@media (max-width: 650px) {

    .mx-8 {
        margin-right: 6px !important;
        margin-left: 6px !important;
    }
}
</style>
