<template>
    <v-card class="card mt-2"
            :class="{ 'bg-blue': item.selected }"
            @click=onClick>
        <div class="d-flex align-center">
            <div v-if="hasIcon"
                 style="width: 40px;">
                <v-img :src="item.icon"
                       style="height: 32px;margin: auto;"
                       contain>
                </v-img>
            </div>
            <div class="text-black"
                 style="font-size: 14px;"
                 :class="{ 'ml-3': selectedLanguage == 'en','mr-3': selectedLanguage == 'ar', 'text-color': !item.selected, 'text-white': item.selected, 'mt-1': !hasIcon }"
                 v-text="$t(item.heading)"></div>
            <v-spacer></v-spacer>

            <div v-if="!item.selected"
                 style="width: 21px;">
                <v-icon v-if="selectedLanguage == 'ar'" class="chevron-right">
                    mdi-chevron-left</v-icon>
                    <v-icon v-if="selectedLanguage == 'en'" class="chevron-right">
                    mdi-chevron-right</v-icon>
            </div>

            <div v-if="item.selected"
                 style="width: 21px;">
                <v-icon v-if="selectedLanguage == 'ar'" class="selected">
                    mdi-chevron-left</v-icon>
                    <v-icon v-if="selectedLanguage == 'en'" class="selected">
                    mdi-chevron-right</v-icon> 
            </div>
        </div>
    </v-card>
</template>

<script>
export default {
    props: {
        type: {
            default: 'topic',
            type: String
        },
        item: {
            default: {},
            type: Object
        },
        hasIcon: {
            default: false,
            type: Boolean
        },
        selectedLanguage: {
            default: 'en',
            type: String
        }
    },
    methods: {
        onClick()
        {
            this.$emit('selected', { item: this.item, type: this.type })
        }
    }
}
</script>

<style lang="scss" scoped>
.bg-blue {
    background: #016FD0;

}

.text-color {
    color: #00175A;
}

.text-white {
    color: white;
}

.selected {
    color: white !important;
    background-color: #016FD0;
}
</style>
